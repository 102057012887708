import React, { useState } from "react";
import { Label } from "../components/ui/label";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { toast } from "react-toastify";

const FormPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [projectName, setProjectName] = useState('');
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [responseData, setResponseData] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        toast.info("Submitting project data...");

        const apiUrl = `https://sentinel.solidhash.io/project/quick`;
        const requestData = {
            email: email,
            password: password,
            project_name: projectName
        };

        try {
            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestData),
            });

            const json = await response.json();

            if (response.ok) {
                setResponseData(json);
                setFormSubmitted(true);
            } else {
                toast.error(json.message || "An error occurred");
            }
        } catch (e) {
            console.error(e);
            toast.error("Failed to submit data.");
        }

        toast.dismiss();
    };

    const handleBack = () => {
        setFormSubmitted(false);
        setEmail('');
        setPassword('');
        setProjectName('');
        setResponseData(null);
    };

    return (
        <div className="w-[90%] mx-auto py-12 flex items-start justify-center">
            <div className="flex items-center justify-center max-w-2xl w-full border p-8 rounded shadow-lg bg-gray-900">
                <div className="mx-auto grid gap-6 w-full">
                    {formSubmitted && responseData ? (
                        <div className="text-center">
                            <h1 className="text-3xl font-bold text-white">Project Created</h1>
                            <p className="text-white mt-4">Platform ID:</p>
                            <p className="text-blue-500">{responseData.platform_id}</p>
                            <p className="text-white mt-4">UUID:</p>
                            <p className="text-blue-500">{responseData.uuid}</p>
                            <p className="text-white mt-4">PID:</p>
                            <p className="text-blue-500">{responseData.pid}</p>
                            <p className="text-white mt-4">API Key:</p>
                            <p className="text-blue-500">{responseData.api_key}</p>
                            <Button onClick={handleBack} className="mt-6 bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 rounded">
                                Back
                            </Button>
                        </div>
                    ) : (
                        <>
                            <h1 className="text-3xl font-bold text-white">Quick Create Project</h1>
                            <form className="grid gap-4" onSubmit={handleSubmit}>
                                <div className="grid gap-2">
                                    <Label htmlFor="email" className="text-white">Email</Label>
                                    <Input
                                        id="email"
                                        name="email"
                                        type="email"
                                        placeholder="Enter email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className="bg-gray-800 text-white placeholder-gray-500"
                                        required
                                    />
                                </div>
                                <div className="grid gap-2">
                                    <Label htmlFor="password" className="text-white">Password</Label>
                                    <Input
                                        id="password"
                                        name="password"
                                        type="password"
                                        placeholder="Enter password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        className="bg-gray-800 text-white placeholder-gray-500"
                                        required
                                    />
                                </div>
                                <div className="grid gap-2">
                                    <Label htmlFor="projectName" className="text-white">Project Name</Label>
                                    <Input
                                        id="projectName"
                                        name="projectName"
                                        type="text"
                                        placeholder="Enter project name"
                                        value={projectName}
                                        onChange={(e) => setProjectName(e.target.value)}
                                        className="bg-gray-800 text-white placeholder-gray-500"
                                        required
                                    />
                                </div>
                                <Button type="submit" className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 rounded">
                                    Submit
                                </Button>
                            </form>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FormPage;
